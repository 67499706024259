let helpers = require('../scripts/helpers');

//
// Vue
//

const form = new Vue({
    // el: '#contract-amounts-form', // defined in $mount function

    data: {
        loading: true,
        periods: [],
        amount: null,
        // deliveryDateFrom: null,
        // deliveryDateTo: null,
    },

    computed: {
        amountAllocated: function() {
            let amountAllocated = 0;

            $.each(this.periods, function(index, period) {
                amountAllocated += isNaN(parseFloat(period.amount)) ? 0 : parseFloat(period.amount);
            });

            return amountAllocated;
        },

        amountToAllocate: function() {
            return this.amount - this.amountAllocated;
        }
    },

    methods: {
        // addPeriod() {
        //     this['periods'].push({value: '-', label: 'tbd.', amount: 0});
        // },

        // removePeriod(key) {
        //     this['periods'].splice(key, 1);

        //     if(this['periods'].length <= 0) this.addPeriod();
        // },

        numbersWithThousands(value) {
            return helpers.numberWithThousands(value);
        }
    },

    mounted() {
        this['periods'] = helpers.initData(null, 'periods');

        this.amount = helpers.initData('amount_value', 'contract');

        // this.deliveryDateFrom = helpers.getDateObject(helpers.dateToYYYYMMDD(helpers.initData('delivery_date_from', 'contract')));
        // this.deliveryDateTo = helpers.getDateObject(helpers.dateToYYYYMMDD(helpers.initData('delivery_date_to', 'contract')));

        this.loading = false;
    },
});

module.exports = form;
