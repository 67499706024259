let helpers = require('../scripts/helpers');

//
// Vue
//

const form = new Vue({
    // el: '#company-form', // defined in $mount function

    data: {
        country: null,
    },

    mounted() {
        this.country = helpers.initData('country');
    },

    watch: {
        country: function(newValues, oldValues) {
            this.$nextTick(function() { $( '.my-selectpicker#country' ).selectpicker('val', newValues); });
        },
    }
});

module.exports = form;
