let helpers = require('../scripts/helpers');

//
// Vue
//

const form = new Vue({
    // el: '#contract-details-form', // defined in $mount function

    data: {
        loading: true,

        dateRaw: null,

        amounts: {},
        amountText: null,
        amountValue: null,
        amountSelected: {},

        products: {},
        productId: null,
        productText: null,
        productSelected: {},
        productSurcharge: null,

        parities: {},
        parityTag: null,
        parityText: null,

        periodText: null,
        deliveryDateFromRaw: null,
        deliveryDateToRaw: null,
        deliverySplit: false, // Todo: implement split delivery

        pricingText: null,
        pricingValue: 0.00.toFixed(2),
        pricingType: null,
        pricingTypes: {},
        pricingMethod: null,
        pricingMethods: {},

        packagings: {},
        packagingText: null,
        packagingSelected: {},

        payments: {},
        paymentText: null,
        paymentSelected: {},

        contracts: {},
        contractText: null,
        contractSelected: {},

        commissionValueOwn: 0.50.toFixed(2),
        commissionValueCo: 0.00,
        commissionText: null,

        additionalAgreements: {},
        additionalAgreementSelected: {},
        additionalAgreementText: null,

        // Todo: Add 'default' (1/0) column in boilerplates table...
    },

    computed: {
        _parityError: function() {
            if(this.parityText && this.parityText.includes(' STADT')) {
                return 'STADT ersetzen';
            }
        },

        _deliveryDateToError: function() {
            if(this.deliveryDateToRaw === null) return;

            if(this.deliveryDateToRaw < this.deliveryDateFromRaw)
                return 'Dieses Datum liegt vor dem Liefertermin (von)';

            if(this.deliveryDateToRaw === this.deliveryDateFromRaw)
                return 'Dieses Datum ist gleich dem Liefertermin (von)';
        },

        orderedProducts: function() { return _.orderBy(this.products, 'code') },
        orderedParities: function() { return _.orderBy(this.parities, 'tag') },
        orderedPackagings: function() { return _.orderBy(this.packagings, 'tag') },
        orderedPayments: function() { return _.orderBy(this.payments, 'tag') },
        orderedContracts: function() { return _.orderBy(this.contracts, 'tag') },
    },

    methods: {
        selectDropdown(variable, options = null, current = null, field = 'body') {
            current = current == null ? helpers.initData(variable) : current;

            if(options == null) {
                options = variable.slice(-1) === 'y' ? variable.slice(0, -1) + 'ies' : variable + 's';
            }

            options = _.camelCase(options)
            variable = _.camelCase(variable)

            // let defaultValue = this[options].find(o => o['default'] === 1)
            let existing = this[options].find(o => o[field] === current);

            if(existing && this[variable + 'Selected']) {
                this[variable + 'Selected'] = existing;
            }
            //
            // else if ( ! existing && defaultValue) {
            //     this[variable + 'Selected'] = defaultValue;
            // }
        },

        setTextToSelected(variable, field = 'body') {
            this[variable + 'Text'] = this[variable + 'Selected'][field];
        },

        setAmountText() {
            if(_.isEmpty(this.amountSelected)) {
                this.amountText = '';
            } else {
                this.amountText = this.amountSelected.body
                    .replace('{{amount}}', helpers.numberWithThousands(this.amountValue));
            }
        },

        setProductSurcharge(value = null) {
            value = typeof value === 'string' ? parseFloat(value) : value;

            if(value !== null)
                this.productSurcharge = value.toFixed(2);

            else if( ! _.isEmpty(this.productSelected))
                this.productSurcharge = this.productSelected.surcharge.toFixed(2);
        },

        setParityText() {
            this.parityText = this.parities.find(parity => parity.tag === this.parityTag).body;
        },

        setPeriodText() {
            this.periodText = '';

            if(this.amountValue == null || this.deliveryDateFromRaw == null || this.pricingType == null) return;

            this.periodText += helpers.stringContains(this.parityTag, ['CIFFO', 'Franko']) ? 'Lieferung' : 'Abnahme';

            this.periodText += ' ca. {{amount}} mto'
                .replace('{{amount}}', helpers.numberWithThousands(this.amountValue));

            this.periodText += this.amountSelected.tag.includes('+/-5%') ? ' +/- 5%' : '';

            this.periodText += this.pricingType === 'Basis' ? ' gesamt' : '';

            if(this.deliveryDateFromRaw !== null) {
                if(this.deliveryDateToRaw !== null) {
                    this.periodText += ' vom {{delivery_date_from}} – {{delivery_date_to}}'
                        .replace('{{delivery_date_from}}', helpers.getDateObject(this.deliveryDateFromRaw)
                            .toLocaleDateString(document.documentElement.lang, { year: 'numeric', month: '2-digit', day: '2-digit' }))
                        .replace('{{delivery_date_to}}', helpers.getDateObject(this.deliveryDateToRaw)
                            .toLocaleDateString(document.documentElement.lang, { year: 'numeric', month: '2-digit', day: '2-digit' }));
                } else {
                    this.periodText += ' am {{delivery_date_from}}'
                        .replace('{{delivery_date_from}}', helpers.getDateObject(this.deliveryDateFromRaw)
                            .toLocaleDateString(document.documentElement.lang, { year: 'numeric', month: '2-digit', day: '2-digit' }));
                }
            }

            if(this.deliveryDateToRaw !== null) {
                if(this.pricingType === 'Verbandlich') {
                    this.periodText += ', nach rechtzeitiger Abstimmung';
                } else if(this.pricingType === 'Basis') {
                    this.periodText += ', in Käufers Wahl';

                    if(helpers.stringContains(this.parityTag, ['CIFFO', 'Franko'])) {
                        this.periodText += ', innerhalb von 10 Geschäftstagen nach Käufers Abruf';
                    }
                }
            }
        },

        setPricingText() {
            this.pricingText = '';

            if(this.pricingType == null || this.pricingMethod == null || this.deliveryDateFromRaw == null) return;

            // Value
            this.pricingText += '{{value}} EUR/mto zzgl. der gesetzlichen MwSt.\n'
                .replace('{{value}}', helpers.numberAsDecimal(this.pricingValue));

            // Matif
            if(this.pricingMethod === 'against actuals' || this.pricingMethod === 'on the screen') {
                this.pricingText += 'Über Matif {{matif_month}} (Milling Wheat No. 2) Kontrakt {{delivery_year}}\n'
                    .replace('{{matif_month}}', this.getMatif())
                    .replace('{{delivery_year}}', helpers.getDateObject(this.deliveryDateFromRaw).getFullYear());
            }

            // Base and Surcharge
            if(this.pricingType === 'Basis') {
                this.pricingText += 'Basis {{basis_month}}, ab dem {{surcharge_from}}\n'
                    .replace('{{basis_month}}', this.getBaseMonth())
                    .replace('{{surcharge_from}}', this.getSurchargeFrom());

                this.pricingText += 'Aufgeld {{surcharge}} EUR pro mto und halben Monat\n'
                    .replace('{{surcharge}}', helpers.numberAsDecimal(this.productSurcharge));
            }

            if(this.pricingMethod !== 'Flat') {
                this.pricingText += 'Pricing in Verkäufers Wahl, {{method}}.\n'
                    .replace('{{method}}', this.pricingMethod);

                this.pricingText += 'Der Käufer hat das Recht, Pricings, welche außerhalb von 11:00 Uhr bis 17:00 Uhr\n' +
                               'oder an Wochenenden und Feiertagen erfolgen, abzulehnen.\n';

                this.pricingText += 'Das Pricing muss am Tag der Andienung, aber spätestens bis zum {{pricing_due}} erfolgen.'
                    .replace('{{pricing_due}}', this.getPricingDue());
            }
        },

        setCommissionText() {
            this.commissionText = this.commission.body
                .replace('{{commission}}', helpers.numberAsDecimal(parseFloat(this.commissionValueOwn) + parseFloat(this.commissionValueCo)));
        },

        selectAdditionalAgreements() {
            // Preselect additional agreements tag 'SO' for the preselected product codes.
            let preselected = ['D F-Wz', 'EU F-Wz', 'D F-Gerste', 'EU F-Gerste', 'EU Mais', 'EU F-Roggen', 'EU Triti'];

            this.additionalAgreementSelected = helpers.stringContains(this.productSelected.code, preselected) ? this.additionalAgreements[0] : {};

            this.setTextToSelected('additionalAgreement');
        },

        getMatifMonth() {
            // delivery_month: matif_month
            let matifs = { 0: 2, 1: 2, 2: 2, 3: 4, 4: 4, 5: 4, 6: 8, 7: 8, 8: 11, 9: 11, 10: 11, 11: 11 }

            return matifs[helpers.getDateObject(this.deliveryDateFromRaw).getMonth()];
        },

        getMatif() {
            return new Date(2020, this.getMatifMonth())
                .toLocaleDateString(document.documentElement.lang, { month: 'long' })
        },

        getBaseMonth() {
            return helpers.getDateObject(this.deliveryDateFromRaw)
                .toLocaleDateString(document.documentElement.lang, { year: 'numeric', month: 'long' })
        },

        getSurchargeFrom() {
            return new Date(helpers.getDateObject(this.deliveryDateFromRaw).getFullYear(), helpers.getDateObject(this.deliveryDateFromRaw).getMonth() + 1, 1)
                .toLocaleDateString(document.documentElement.lang, { year: 'numeric', month: 'long', day: 'numeric' })
        },

        getPricingDue() {
            let dueDates = {
                2: new Date(helpers.getDateObject(this.deliveryDateFromRaw).getFullYear(), 1, 15),
                4: new Date(helpers.getDateObject(this.deliveryDateFromRaw).getFullYear(), 3, 14),
                8: new Date(helpers.getDateObject(this.deliveryDateFromRaw).getFullYear(), 7, 14),
                11: new Date(helpers.getDateObject(this.deliveryDateFromRaw).getFullYear(), 10, 15),
            }

            return dueDates[this.getMatifMonth()]
                .toLocaleDateString(document.documentElement.lang, { year: 'numeric', month: 'long', day: 'numeric' })
        },
    },

    mounted() {
        axios.get("/api/v1/contract-details")
            .then(response => {

                // Date
                this.dateRaw =  helpers.dateToYYYYMMDD(
                    helpers.initData('date') !== null ?
                    helpers.initData('date') : new Date()
                );

                // Amount
                this.amounts = response.data.boilerplates.amounts;
                this.amountText = helpers.initData('amount');
                this.amountValue = helpers.initData('amount_value');
                this.selectDropdown('amount', 'amounts', helpers.initData('amount_tag'), 'tag');

                // Product
                this.products = response.data.boilerplates.products;
                this.productId = helpers.initData('product_id');
                this.productText = helpers.initData('product_description');
                this.selectDropdown('product', 'products', helpers.initData('product_id'), 'id');
                this.setProductSurcharge(helpers.initData('product_surcharge'));

                // Parity
                this.parities = response.data.boilerplates.parities;
                this.parityTag = helpers.initData('parity_tag');
                this.parityText = helpers.initData('parity');

                // Period
                this.periodText = helpers.initData('period');
                this.deliveryDateFromRaw = helpers.dateToYYYYMMDD(helpers.initData('delivery_date_from'));
                this.deliveryDateToRaw = helpers.dateToYYYYMMDD(helpers.initData('delivery_date_to'));

                // Pricing
                this.pricingText = helpers.initData('pricing');
                if(helpers.initData('pricing_value') !== null)
                    this.pricingValue = helpers.initData('pricing_value');
                this.pricingType = helpers.initData('pricing_type');
                this.pricingTypes = response.data.boilerplates.pricingTypes;
                this.pricingMethod = helpers.initData('pricing_method');
                this.pricingMethods = response.data.boilerplates.pricingMethods;

                // Packaging
                this.packagings = response.data.boilerplates.packagings;
                this.packagingText = helpers.initData('packaging');
                this.selectDropdown('packaging');

                // Payment
                this.payments = response.data.boilerplates.payments;
                this.paymentText = helpers.initData('payment');
                this.selectDropdown('payment');

                // Contract
                this.contracts = response.data.boilerplates.contracts;
                this.contractText = helpers.initData('contract');
                this.selectDropdown('contract');

                // Commission
                this.commission = response.data.boilerplates.commission;
                if(helpers.initData('commission_value_own') !== null)
                    this.commissionValueOwn = parseFloat(helpers.initData('commission_value_own')).toFixed(2);
                if(helpers.initData('commission_value_co') !== null)
                    this.commissionValueCo = parseFloat(helpers.initData('commission_value_co')).toFixed(2);
                if(helpers.initData('commission') !== null)
                    this.commissionText = helpers.initData('commission');
                else this.setCommissionText();

                // Additional Agreement
                this.additionalAgreements = response.data.boilerplates.additionalAgreements;
                this.additionalAgreementText = helpers.initData('additional_agreement');
                this.selectDropdown('additional_agreement');

                // setTimeout(function (){
                //     $( '.selectpicker' ).selectpicker('refresh');
                // }, 100);

                this.$nextTick(function () {
                    $( '.my-selectpicker' ).selectpicker( 'refresh' );
                });

                this.loading = false;
            });
    },

    watch: {
        additionalAgreementSelected: function(newValues, oldValues){
            this.$nextTick(function(){ $('#additional_agreements').selectpicker('refresh'); });
        }
    }

});

module.exports = form;
