let helpers = require('../scripts/helpers');

//
// Vue
//

const form = new Vue({
    // el: '#product-form', // defined in $mount function

    data: {
        type: null,
        quality: null,
        country: null,
        crop_year: null,
        qualities: {
            'Dinkel': ['Dinkel'],
            'Erbsen': ['Futtererbsen (gelb)'],
            'Gerste': ['Futtergerste'],
            'Hafer': ['Futterhafer'],
            'Mais': ['Mais'],
            'Roggen': ['Brotroggen', 'Futterroggen', 'Roggen', 'Roggenschrot'],
            'Soja': ['Sojabohnen'],
            'Triticale': ['Triticale'],
            'Weizen': ['A-Weizen', 'B-Weizen', 'Brotweizen', 'Futterweizen', 'Qualitätsweizen', 'Weizen']
        }
    },

    computed: {
        quality_options: function() {
            return this.qualities[this.type];
        },
    },

    mounted() {
        this.type = helpers.initData('type');
        this.quality = helpers.initData('quality');
        this.country = helpers.initData('country');
        this.crop_year = helpers.initData('crop_year');

        this.$nextTick(function () {
            $( '.my-selectpicker' ).selectpicker( 'refresh' );
        });
    },

    watch: {
        type: function(newValues, oldValues) {
            this.$nextTick(function() {
                // $( '.selectpicker#quality' ).selectpicker('val', newValues);
                $( '.my-selectpicker#quality' ).selectpicker('refresh');
            });
        },
    }
});

module.exports = form;
