//
// Loading
//

/**
 * If no #data-table exists hide the loading
 * spinner after loading the page and show
 * the relevant content.
 *
 * Otherwise the loading will be handled in the
 * DataTable Callback 'initComplete'.
 */

if( ! $( "#data-table" ).length) {
    $( window ).on( 'load' , function () {
        $( '.loading[data-trigger="load"]' ).hide();
        $( '.after-loading-show' ).show();
    });
}
