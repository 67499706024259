/**
 * Main Navigation Side Bar
 */

var mainMenuSelector = '#main-menu';
var subMenuSelector = '#sub-menu';

$( document ).ready( function() {
    let mainMenuItem = $( '[data-sub-menu]' );

    // Hover the main menu
    mainMenuItem.hover( function () {
        selectMenu( $( this ) );
    });

    // Leave a main menu's item
    mainMenuItem.mouseleave( function ( event ) {
        changeMenuToActiveRoute( event );
    });

    // Leave the sub menu
    $( subMenuSelector ).mouseleave( function( event ) {
        changeMenuToActiveRoute( event );
    });

});


/**
 *  If the cursor leaves the the designated parts of the menu,
 *  the active state of the menu will be reset according to
 *  the current route.
 *
 * @param event
 */

function changeMenuToActiveRoute( event ) {
    let relatedTarget = $( event.relatedTarget );

    // If the related/new target has one of the following
    // classes or a parent of .sub-menu don't change anything.
    if(relatedTarget.is('.menu-header, .menu-bar, .menu-item, .menu-footer, .sub-menu') || relatedTarget.parents('.sub-menu').length === 1)
        return;

    selectMenu( $( "[data-menu-status='active']" ) );
}

/**
 * Select the main-menu and sub-menu for the
 * selectedItem.
 *
 * @param selectedItem
 */

function selectMenu( selectedItem )
{
    // Remove the active class from all #main-menu .menu-items
    $('#main-menu .menu-item').each( function() {
        $( this ).removeClass('active');
    });

    // Add the active class to the #main-menu .menu-item
    // for the selected item
    selectedItem.addClass('active');

    // Remove the active class from all #sub-menu .menu-bars
    $('#sub-menu .menu-bar').each( function() {
        $( this ).removeClass('active');
    });

    // Add the active class to the #sub-menu .menu-bar
    // for the selected item
    $( subMenuSelector + ' .menu-bar[data-menu-id="' + selectedItem.attr( 'data-sub-menu' ) + '"]').addClass( 'active' );
}
