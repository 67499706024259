/**
 * Adds tab hash to url in address bar.
 */

$( document ).ready( function() {
    var hash = window.location.hash;

    if ( hash ) {
        $('.nav.nav-tabs a[href="' + hash + '"]').tab('show');
    }

    $('.nav-tabs a').click(function (e) {
        window.location.hash = this.hash;
    });
});
