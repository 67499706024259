const form = new Vue({
    // el: '#contract-parties-form', // defined in $mount function

    data: {
        loading: true,
        locale: 'de',
        companies: null,
        seller: {},
        buyer: {},
        brokers: {},
        selectedBrokers: [],
        contacts: null,
        sellerRecipients: [{id: '', company_id: '', first_name: '', last_name: '', email: ''}],
        buyerRecipients: [{id: '', company_id: '', first_name: '', last_name: '', email: ''}],
    },

    computed: {
        sellerContacts: function() {
            if(Object.keys(this.seller).length === 0 && this.seller.constructor === Object) return [];

            return _.orderBy(this.contacts.filter((contact) => {
                return contact.company_id === this.seller.id;
            }), 'last_name');
        },

        buyerContacts: function() {
            if(Object.keys(this.buyer).length === 0 && this.buyer.constructor === Object) return [];

            return _.orderBy(this.contacts.filter((contact) => {
                return contact.company_id === this.buyer.id;
            }), 'last_name');
        },

        orderedCompanies: function() { return _.orderBy(this.companies, 'name') },
        orderedBrokers: function() { return _.orderBy(this.brokers, 'last_name') },
    },

    methods: {
        addRecipient(type, recipient = null) {
            if(recipient === null) {
                this[type + 'Recipients'].push({id: '', first_name: '', last_name: '', email: ''});
            } else {
                this[type + 'Recipients'].push(recipient);
            }
        },

        removeRecipient(type, key) {
            this[type + 'Recipients'].splice(key, 1);

            if(this[type + 'Recipients'].length <= 0) this.addRecipient(type);
        },

        resetRecipients(type) {
            this[type + 'Recipients'] = [];
            this.addRecipient(type);
        },

        addBroker(broker = null) {
            if(broker === null) {
                this.selectedBrokers.push({id: '', first_name: '', last_name: ''});
            } else {
                this.selectedBrokers.push(broker);
            }
        },

        removeBroker(key) {
            this.selectedBrokers.splice(key, 1);

            if(this.selectedBrokers.length <= 0) this.addBroker();
        }
    },

    mounted() {
        axios.get("/api/v1/contract-parties")
            .then(response => {
                this.companies = response.data.data.companies;
                this.contacts = response.data.data.contacts;
                this.brokers = response.data.data.brokers;

                if (typeof contract !== 'undefined') {
                    this.seller = this.companies.find(company => company.id === contract.seller_id);
                    this.buyer = this.companies.find(company => company.id === contract.buyer_id);
                    this.locale = contract.locale;
                }

                if (typeof sellerRecipients !== 'undefined' && sellerRecipients.length > 0) {
                    this.sellerRecipients = [];

                    $.each(sellerRecipients, function(index, recipient){
                        form.addRecipient('seller', recipient);
                    });
                }

                if (typeof buyerRecipients !== 'undefined' && buyerRecipients.length > 0) {
                    this.buyerRecipients = [];

                    $.each(buyerRecipients, function(index, recipient){
                        form.addRecipient('buyer', recipient);
                    });
                }

                if (typeof contract !== 'undefined' && typeof contract.brokers !== 'undefined' && contract.brokers.length > 0) {
                    this.selectedBrokers = [];

                    $.each(contract.brokers, function(index, broker){
                        form.addBroker(broker);
                    });
                } else {
                    form.addBroker(auth);
                }

                this.loading = false;
            })
    },

    watch: {
        companies: function(newValues, oldValues) {
            this.$nextTick(function() { $( '.my-selectpicker' ).selectpicker('refresh'); });
        },
        sellerRecipients: function(newValues, oldValues) {
            this.$nextTick(function() { $( '.my-selectpicker[data-recipient-type="seller"]' ).selectpicker('refresh'); });
        },
        buyerRecipients: function(newValues, oldValues) {
            this.$nextTick(function() { $( '.my-selectpicker[data-recipient-type="buyer"]' ).selectpicker('refresh'); });
        },
    }
});

module.exports = form;
