let helpers = require('../scripts/helpers');

//
// Vue
//

const form = new Vue({
    // el: '#contact-form', // defined in $mount function

    data: {
        companyId: null,
    },

    mounted() {
        this.companyId = helpers.initData('company_id');
    },

    watch: {
        companyId: function(newValues, oldValues) {
            this.$nextTick(function() { $( '.my-selectpicker#company_id' ).selectpicker('val', newValues); });
        },
    }
});

module.exports = form;
