/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// Bootstrap
require('./bootstrap');

// Bootstrap Select
// require('bootstrap-select/js/bootstrap-select');
require('bootstrap-select/js/bootstrap-select');

// Bootstrap Datepicker
// require('bootstrap-datepicker/js/bootstrap-datepicker');
// require('bootstrap-datepicker/js/locales/bootstrap-datepicker.de');


// Custom modules
require('./modules/nav-tabs');
require('./modules/side-menu');
require('./modules/loading');

// Vue.js modules
window.Vue = require('vue');

const companyForm = require('./modules/companies')
const contactForm = require('./modules/contacts')
const contractPartiesForm = require('./modules/contract-parties');
const contractDetailsForm = require('./modules/contract-details');
const contractAmountsForm = require('./modules/contract-amounts');

const productForm = require('./modules/products');

require('./modules/invoices-create');



/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

jQuery(function() { 

    /**
     * Vue Instances
     */

    if(document.querySelectorAll( '[data-vue="company"]' ).length) {
        companyForm.$mount( '[data-vue="company"]' );
    }

    if(document.querySelectorAll( '[data-vue="contact"]' ).length) {
        contactForm.$mount( '[data-vue="contact"]' );
    }

    if(document.querySelectorAll( '[data-vue="product"]' ).length) {
        productForm.$mount( '[data-vue="product"]' );
    }

    if(document.querySelectorAll( '[data-vue="contract-parties"]' ).length) {
        contractPartiesForm.$mount( '[data-vue="contract-parties"]' );
    }

    if(document.querySelectorAll( '[data-vue="contract-details"]' ).length) {
        contractDetailsForm.$mount( '[data-vue="contract-details"]' );
    }

    if(document.querySelectorAll( '[data-vue="contract-amounts"]' ).length) {
        contractAmountsForm.$mount( '[data-vue="contract-amounts"]' );
    }

    /**
     * Bootstrap Tooltips
     */

    $( '[data-toggle="tooltip"]' ).tooltip({ boundary: 'window' });

    /**
     * Select Picker
     */

    $('.my-selectpicker').selectpicker();

    /**
     * Datepicker
     */

    // $( '.datepicker' ).datepicker({ language: "de" });

    /**
     * Disable the submit button on submitting a form
     */

    $( 'form' ).on( 'submit', function( e ) {
        $( 'button[type="submit"]' ).attr( 'disabled', true );
    });

    /**
     * After submitting the form hide the relevant
     * content and show the loading spinner.
     */

    $( 'form.after-submit-loading' ).on( 'submit', function( e ) {
        $( '.loading' ).show(); // @TODO: only the closest loading in the form should show
        $( '.after-loading-show' ).hide();
    });

    /**
     * Show an item when focusing another item
     */

    $( '[data-show-focus]' )
        .on( 'focus', function( e ) {
            $( '[data-show-on="' + e.target.id + '"]' ).fadeIn();
        })
        .on( 'focusout', function( e ) {
            $( '[data-show-on="' + e.target.id + '"]' ).fadeOut();
        });

    /**
     * Confirm cancel dialog
     */

    $( '[data-confirm="cancel"]' ).on( 'click', function( event ) {
        return confirm($( this ).attr('data-confirm-message'))
    });

});
