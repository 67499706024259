jQuery(function() {

    /**
     * Remove position from invoice.
     */

    $( '[data-table-action="delete"]' ).on( 'click', function( e ) {
        e.target.closest('tr').remove();
    });

});
