//
// Helper Functions
//

/**
 * Converts the given number to a string with thousands separators.
 *
 * @param x
 * @returns {string}
 */
export function numberWithThousands(x) {
    if(x == null) return '';

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

/**
 * Converts the given number 'x' to a 2-digit float number.
 *
 * @param x
 * @returns {string}
 */
export function numberAsDecimal(x) {
    if(x == null) return '';

    return new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(x);
}

/**
 * Converts the given date to a YYYY-MM-DD string.
 *
 * @param date
 * @returns {string|null}
 */
export function dateToYYYYMMDD(date) {
    if(date === null) return null;

    date = typeof date === 'string' ? new Date(date) : date;

    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

/**
 * Returns a Date object of a YYYY-MM-DD string.
 *
 * @param value
 * @returns {null|Date}
 */
export function getDateObject(value) {
    if(value === '' || value === null) {
        return null;

    } else if(value instanceof Date) {
        return value;

    } else {
        let [y, m, d] = value.split('-');

        return new Date(y, m - 1, d);
    }
}

/**
 * Checks if the string contains any of the given keywords.
 *
 * @param string
 * @param keywords
 * @param case_sensitive
 * @returns {boolean}
 */
export function stringContains(string, keywords, case_sensitive = false) {
    let found = false;

    string = case_sensitive ? string.toLowerCase() : string;

    $.each(keywords, function(index, keyword) {
        keyword = case_sensitive ? keyword.toLowerCase() : keyword;

        if(string.includes(keyword)) {
            found = true;
            return false;
        }
    });

    return found;
}

/**
 * Camelizes the given string:
 *
 * camelize("EquipmentClass name");
 * camelize("Equipment className");
 * camelize("equipment class name");
 * camelize("Equipment Class Name");
 * // all output "equipmentClassName"
 *
 * @param str
 * @returns {*}
 */
export function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

/**
 * Returns the window.old variable if it exists or else
 * the window.contract value.
 *
 * @param variable
 * @param context
 * @returns {*}
 */
export function initData(variable, context = 'object') {
    if(typeof old !== 'undefined') {
        if (variable !== null) {
            return old[variable] ? old[variable] : null;
        }

        return old ? old : null;
    }
        

    if(typeof window[context] !== 'undefined') {
        if (variable !== null) {
            return window[context][variable] ? window[context][variable] : null;
        }

        return window[context] ? window[context] : null;
    }
        

    return null;
}
